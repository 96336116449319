module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-161994008-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Created for Crisis","short_name":"CFC Website","description":"Created For Crisis is an international design and research team with a focus on humanitarian and public health needs.","start_url":"/","background_color":"#FFFFFF","theme_color":"#10375C","display":"standalone","icon":"src/assets/favicons/CfC-Favicon-64.png","icons":[{"src":"/favicons/CfC-Favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/CfC-Favicon-512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
